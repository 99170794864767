import "./Nav.css";
import { Link } from "react-router-dom";

export default function Nav() {
  return (
    <div className="nav">
      <Link to="/" className="navLink">
        <div className="title">
          <b>W1ntermuted's Completion Notes</b>
        </div>
      </Link>
      <div className="leftNav">
        <a
          className="navLink"
          href={"https://series-setlist-checker.w1ntermuted.dev"}
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          <i className="subSection">Series Setlist Checker</i>
        </a>
        <a
          className="navLink"
          href={"https://www.trueachievements.com/gamer/W1ntermuted"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="subSection">TrueAchievements</i>
        </a>
        <a
          className="navLink"
          href={"https://www.youtube.com/@W1ntermuted"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="subSection">YouTube</i>
        </a>
      </div>
    </div>
  );
}
